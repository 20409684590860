import React from 'react'
import './Modal.scss'

const Modal = ({ active, children, classes, styles = {}, close, title, description }) => {
    return (
        <div className={active ? "modal active" : "modal"} style={styles} onClick={close}>
            <div className={`modal__content  ${classes ? classes : ''}`} onClick={(e) => e.stopPropagation()}>
                {(title || description) && <header className="modal__header">
                    <div>
                        {title && <p className="modal__title">{title}</p>}
                        {description && <p className="modal__description">{description}</p>}
                    </div>
                    <button className="modal__x-close" onClick={close}></button>
                </header>}
                {children}
            </div>
        </div>
    )
}

export default Modal