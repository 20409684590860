import React, { useEffect, useState } from 'react'
import { onPhoneInput } from '../../../functions/mask'
import Request from '../../../api/Request'
import FormItem from '../../UI/fields/FormItem'
import './ContactsForm.scss'
import { useParams } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import FormError from '../../UI/FormError/FormError'
import FormSuccess from '../../UI/FormSuccess/FormSuccess'
import urls from '../../../urls'

const ContactsForm = () => {
    const request = new Request()
        , { t, i18n } = useTranslation()
        , { lng } = useParams()
        , [btnActive, setBtnActive] = useState(false)
        , [formError, setFormError] = useState(false)
        , [formSuccess, setFormSuccess] = useState(false)
        , [fields, setFields] = useState([
            // {
            //     name: 'name',
            //     type: 'text',
            //     label: 'Ваше имя',
            //     required: true,
            // },
            // {
            //     name: 'phone',
            //     type: 'tel',
            //     label: 'Телефон',
            //     mask: onPhoneInput,
            //     required: true
            // },
            // {
            //     name: 'email',
            //     type: 'email',
            //     label: 'Электронная почта',
            //     required: true
            // },
            // {
            //     name: 'description',
            //     type: 'textarea',
            //     label: 'Описание проекта',
            //     required: true,
            //     classes: 'col-3'
            // },

        ])
        , file = {
            name: 'file',
            type: 'file',
            label: 'Прикрепить файл',
        }
        , [data, setData] = useState({})

    useEffect(() => {
        request.discussOptions()
            .then(response => {
                console.log('discussOptions', response.data.actions.discuss);
                const obj = response.data.actions.discuss
                setFields([])
                const arr = []
                for (const [key, val] of Object.entries(obj)) {
                    // console.log(key, val)
                    const field = {}
                    field.name = key
                    field.label = val.label
                    field.read_only = val.read_only
                    field.required = val.required
                    switch (key) {
                        case 'name':
                            field.type = 'text'
                            break
                        case 'phone':
                            field.type = 'tel'
                            field.mask = onPhoneInput
                            break
                        case 'email':
                            field.type = 'email'
                            break
                        case 'description':
                            field.type = 'textarea'
                            field.classes = 'col-3'
                            break
                        case 'files':
                            field.type = 'file'
                            break
                        default:
                            field.type = val.type === 'string' ? 'text' : val.type
                    }
                    arr.push(field)

                }
                setFields([...arr])

            })
    }, [lng])

    const setValue = (name, value) => {
        data[name] = value
        value ?? delete data[name]
        if (value === '')
            delete data[name]
        setData({ ...data })
        if (Object.values(data).length >= fields.filter(item => item.required === true).length) {
            setBtnActive(true)
        } else {
            setBtnActive(false)
        }
    }

    const setError = (name, value) => {
        const index = fields.findIndex(field => field.name === name)
        fields[index].error = value
        setFields(fields)
    }

    const setFileError = (name, value) => {
        file.error = value
        setFields(fields)
    }
    const send = () => {
        setFormError(false)
        setFormSuccess(false)
        setBtnActive(false)
        const formData = new FormData()

        for (let [key, val] of Object.entries(data)) {
            formData.append(key, val)
        }


        // for (const pair of formData.entries()) {
        //     console.log(pair[0], pair[1]);
        // }
        request.discussPost(formData)
            .then(response => {
                // console.log('response', response)
                if (response.success) {
                    setFormSuccess(t("discuss.success"))
                } else {
                    setFormError(t("discuss.error"))
                }
                setBtnActive(true)
            })
    }

    return (
        <div className='contacts-form'>
            <div>
                {formError && <FormError message={formError} />}
                {formSuccess && <FormSuccess message={formSuccess} />}
                <div className='contacts-form__container'>
                    {fields.map((item, i) => {
                        if (item.name !== 'files')
                            return <FormItem field={item} key={i} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
                    })}
                </div>
                <div className='contacts-form__control'>
                    {fields.map((item, i) => {
                        if (item.name === 'files')
                            return <FormItem field={file} key={i} mask={false} value={data['file']} setValue={setValue} setError={setFileError} />
                    })}
                    <span className={`contacts-form__btn ${btnActive ? '' : '_disabled'}`} onClick={send}>{t("discuss.button")}</span>
                </div>
            </div>
            <p className='contacts-form__success-text'>
                <Trans
                    i18nKey="discuss.form_success_text"
                    components={[<a  href={`/${lng}/${urls.terms.path}`} target='_blank'>Политику конфиденциальности</a>]}>Нажимая на кнопку «Отправить», вы даете согласие на
                    <a href='' target='_blank'>Политику конфиденциальности</a></Trans></p>
        </div>
    )
}

export default ContactsForm