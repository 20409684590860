const dateValidator = function (data) {
    const day = +data.split('.')[0],
        month = --data.split('.')[1],
        year = +data.split('.')[2],
        date = new Date(year, month, day)

    if (date.getFullYear() == year && date.getMonth() == month && date.getDate() == day)
        return true
    else
        return false
}

const emailValidator = function (value) {
    const re = /\S+@\S+\.\S+/
    return re.test(value) ? false : "Введите корректный email." //  статус ошибки
}

const siteValidator = function (value) {
    const re = /\S+\.\S+/
    return re.test(value) ? false : "Введите корректный адрес сайта, например: мойсайт.рф." //  статус ошибки
}

export {emailValidator, dateValidator, siteValidator}