import React from 'react'
import './GalleryTile.scss'
import apiConfig from '../../api/apiConfig'

const GalleryTile = ({ images }) => {
  return (
    <div className='container-1186'>
      {/* GalleryTile_flex */}
      <div className='GalleryTile'>
        {images.map((img, idx) => {
          return <img key={idx} className='GalleryTile__img' src={`${apiConfig.baseUrl}/${img.url}`} />
        })}
      </div>
    </div>
  )
}

export default GalleryTile