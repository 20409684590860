import React from 'react'
import "./RunningLine.scss"
import { useTranslation } from 'react-i18next';

const RunningLine = (props) => {
    const { t, i18n } = useTranslation()

    const generateLimitedElements = (limit) => {

        let content = [];
        for (let i = 0; i < limit; i++) {
            content.push(
                <div aria-hidden={i != 0 && true} className="items marquee">
                    {props.data.map((item, idx) => {
                        return (
                            <div key={idx} className="item running-line_text">{item.text}</div>
                        )
                    })}
                </div>
            );
        }
        return content;


    };



    const dataLine = 4; // Change 100 to your desired limit




    return (
        <div className="running-line_block">
            <div className="running-line">
                <div className="items-wrap">
                    {generateLimitedElements(dataLine)};
                </div>
                <h2 className='running-line_title'>{t("contacts.title")}</h2>
            </div>
        </div>

    )
}

export default RunningLine