import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './GallerySlider.scss'
import icons from '../../assets/interface_icons/icons';
import apiConfig from '../../api/apiConfig';

const GallerySlider = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [active, setActive] = useState(0)

  let params = {
    modules: [FreeMode, Navigation, Thumbs],
    navigation: {
      nextEl: '.GallerySlider__next',
      prevEl: '.GallerySlider__prev',
    },
    onSwiper: setThumbsSwiper,
    onSlideChange: (e) => setActive(e.activeIndex),
    thumbs: { swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null },
    loop: false,
    spaceBetween: 24,
    className: 'GallerySlider__slider'
  };
  let thumbsParams = {
    onSwiper: setThumbsSwiper,
    loop: false,
    spaceBetween: 4,
    slidesPerView: 14,
    freeMode: true,
    watchSlidesProgress: true,
    modules: [FreeMode, Navigation, Thumbs],
    className: 'GallerySlider__thumbs'
  }

  return (
    <div className='container-1186'>
      <div className='GallerySlider'>
        <Swiper {...params}>
          {images.map((img, idx) => {
            return <SwiperSlide key={idx}>
              {({ isActive }) => (
                <img className={`GallerySlider__img${isActive ? ' _active' : ''}`}  src={`${apiConfig.baseUrl}/${img.url}`} />
              )}

            </SwiperSlide>
          })}
          <div className='GallerySlider__next'>
            <img src={icons.SwiperGalleryArrow} alt="" />
          </div>
          <div className='GallerySlider__prev'>
            <img src={icons.SwiperGalleryArrow} alt="" />
          </div>
        </Swiper>
        <Swiper {...thumbsParams}>
          {images.map((img, idx) => {
            return <SwiperSlide key={idx}>
              <img className={`GallerySlider__thumb-img${idx === active ? ' _active' : ''}`} src={`${apiConfig.baseUrl}/${img.url}`} />
            </SwiperSlide>
          })}

        </Swiper>
      </div>
    </div>
  )
}

export default GallerySlider