export default {
  index: {
    title: "meta.index.title",
    keywords: "",
    description: "meta.index.description",
  },
  contacts: {
    title: "meta.contacts.title",
    keywords: "",
    description: "meta.contacts.description",
  },
};
