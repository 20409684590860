export default (str = Date.now()) => {
    let parsed_date
    try {
        parsed_date = new Date(str)
    } catch {
        console.log('--- Неподдерживаемый формат даты ---', str)
        return ''
    }
    const day = parsed_date.getDate().toString().padStart(2, "0");
    const month = (parsed_date.getMonth() + 1).toString().padStart(2, "0"); // Добавляем ведущий ноль, если месяц меньше 10
    const year = parsed_date.getFullYear();
    // Формируем строку в формате "день месяц год"
    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate
}