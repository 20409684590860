import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./contactsStyles.scss"
import PageFrame from "../../components/PageFrame/PageFrame";
import urls from "../../urls";

import Request from "../../api/Request";
import { useParams } from "react-router-dom";
import { fieldToArray } from "../../functions/functions";
import RunningLine from "../../components/RunningLine/RunningLine";
import Modal from '../../components/UI/Modal/Modal'
import ContactsForm from "../../components/forms/ContactsForm/ContactsForm";
import MetaTagComponent from "../../components/MetaTagComponent/MetaTagComponent";
import meta from "../../meta";
import TermsOfUse from "../TermsOfUse/TermsOfUse";

const ContactsPage = () => {
  const { t, i18n } = useTranslation()
    , request = new Request()
    , { lng } = useParams()
    , [copied, setCopied] = useState(false)
    , requisites_keys = ["name", "certificate", "inn", "ogrnip", "checking_account", "bik", "bank", "correspondent_account"]
    , [modal, setModal] = useState(false)
    , [modalStyles, setModalStyles] = useState({ display: 'none' })

  // data and options
  const [contact, setContact] = useState({})
    , [requisites, setRequisites] = useState({})
    , [options, setOptions] = useState({})
    , [partners, setPartners] = useState({})
    , [runningLine, setRunningLine] = useState()

  useEffect(() => {
    request.getContacts()
      .then(data => {
        setContact(data.data)
      })

    request.getRequisitesOptions()
      .then(data => {
        setOptions(data.data.actions["get-active"])
      })

    request.getRequisites()
      .then(data => {
        setRequisites(data.data.results[0])
      })

    request.getPartners()
      .then(data => {
        setPartners(data.data.results)
      })

    request.getRunningLine()
      .then(data => {
        setRunningLine(data.data.running_texts)
      })

  }, [lng])

  const handleCopyClick = () => {
    const textToCopy = fieldToArray(options).map((item) =>
      (item.key.includes(`_ru`) || item.key.includes(`_en`) || item.key === "id") ?
        "" :
        `${item.value.label}: ${requisites[item.key]}`).join("\n");

    navigator?.clipboard?.writeText(textToCopy.replace(/^\s*[\r\n]/gm, ''));
    setCopied(true);

    // Reset the "copied" state after 3 seconds
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const closeModal = (e) => {
    e.preventDefault()
    setModal(false)
    setTimeout(() => setModalStyles({ display: 'none' }), 300)
  }
  const openModal = (e) => {
    e.preventDefault()
    setModalStyles({ display: 'grid' })
    setTimeout(() => setModal(true), 100)
  }

  const metaTag = {
    "title": t(meta.contacts.title),
    "description": t(meta.contacts.description),
    "keywords": t(meta.contacts.keywords)
  }
  return (
    <PageFrame>
      <MetaTagComponent meta={metaTag} />
      <div className="content">
        {runningLine && <RunningLine data={runningLine} />}
        <div className="contacts-block">
          <address className="d-flex flex-column">
            <a className="contacts-email" href={`tel: ${contact.phone}`}>{contact.phone}</a>
            <a className="contacts-phone" href={`mailto: ${contact.email}`}>{contact.email}</a>
            <div className="contacts-socials">
              {contact?.urls &&
                contact.urls.map((url, idx) => {
                  return (
                    <a key={idx} href={url.url} className={`social-link _${idx}`}>{url.name}</a>
                  )
                })}
            </div>
          </address>
          <div className="discuss-project" onClick={openModal}>
            <span className="discuss-project__btn">{t("discuss.title")}</span>
          </div>
        </div>

        <div className="partners">
          <p className="partners-title">{t("contacts.partners")}</p>
          <div className="partners-list">
            {Object.values(partners).map((partner, idx) => {
              return (
                <a href={partner.url ? partner.url : ""} key={idx} className={`partners-item `}>
                  <span>{partner.name}</span>

                </a>
              )
            })}
          </div>
        </div>

        <hr className="contacts-page_line" />
        <a href={`/${lng}/${urls.terms.path}`} className='partners-title'>{t("terms.title")}</a>

        <div className="requisites">
          <div className="d-flex align-items-center">
            <p className="requisites-title">{t("requisites.title")}</p>
            <span className="copy-btn" onClick={() => handleCopyClick()}>{copied ? t("copy_done.title") : t("copy.title")}</span>
          </div>
          {requisites_keys.map((req, idx) => {
            return (
              <div className="requisites-list" key={idx}>
                <p className="label">{options[req] && options[req].label}</p>
                <p className="data">{requisites[req] && requisites[req]}</p>
              </div>
            )
          })}
        </div>
      </div>
      <Modal active={modal} styles={modalStyles} title={t("discuss.title")} description={t("discuss.description")} close={closeModal} >
        <ContactsForm />
      </Modal>
    </PageFrame >
  );
};

export default ContactsPage;
