import React from "react"
import FieldError from "./FieldError"

function TextItem(props) {
    return (
        <div className={"form__item " + props.classes}>
            <FieldError {...props} />
            <input type={props.type ? props.type : props.field.type || 'text'}
                id={props.field.id}
                name={props.field.name}
                value={props.value}
                onChange={props.handler}
                onBlur={props.blurHandle}
                className={(props.fieldDirty && props.field.error) ? 'error' : props.value ? 'filled' : ''}
                placeholder={props.field.style?.placeholder}
                required={props.required}
                min={props.type === 'number' ? 0 : ''}
                readOnly={props.readOnly} />
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default TextItem