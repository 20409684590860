import React, { useEffect, useRef } from "react"
import FieldError from "./FieldError"

function TextareaItem(props) {
    const input = useRef()

    useEffect(() => {
        input.current.style.height = "1px";
        input.current.style.height = (input.current.scrollHeight || 72) + "px";
    }, [props.value])

    return (
        <div className={`form__item ${props.classes ? props.classes : props.field.classes ? props.field.classes : ''}`}>
            <FieldError {...props} />
            <textarea id={props.field.id}
                style={{ resize: 'none' }}
                className={(props.fieldDirty && props.field.error) ? 'error' : props.value ? 'filled' : ''}
                name={props.field.name}
                value={props.value}
                ref={input}
                onChange={e => props.fieldHandler(e)}
                onBlur={props.blurHandle}
                placeholder={props.field.style?.placeholder}
                required={props.required}
                readOnly={props.readOnly}></textarea>
            <label htmlFor={props.field.name}>{props.field.label}{props.field.required || props.required ? <span>*</span> : ''}</label>
        </div>
    )
}

export default TextareaItem