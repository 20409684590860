export default {
    index: {
        path: '',
        title: 'index.title'
    },
    contacts: {
        path: 'contacts/',
        title: 'contacts.title'
    },
    project: {
        path: 'project/:id',
        title: ''
    },
    terms: {
        path: 'terms-of-use/',
        title: 'terms.title'
    },

}