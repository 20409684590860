import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import Request from "../../api/Request";
import AnimateGerbComponent from '../AnimateGerbComponent/AnimateGerbComponent';
import icons from '../../assets/interface_icons/icons'



const ProjectItem = (props) => {

    // console.log("props", props);

    const { t, i18n } = useTranslation()
        , [isPicture, setIsPicture] = useState(false)

    const renderSwitch = (param) => {
        switch (param) {
            case 'AnimateGerbComponent':
                return <AnimateGerbComponent />;

            default:
                return '';
        }
    }

    useEffect(() => {
        if (props?.data?.tags.length === 1 && (props.data.tags[0].name === "Картинка" || props.data.tags[0].name === "Picture")) {
            setIsPicture(true)
        }
    }, [props.data, props.tags])


    console.log("props", props);

    return (
        <>
            {isPicture ?
                <div className={`fixed-item colItem-${props.data.column}`}>
                    {props.data.header_file &&
                        props.data.header_file.split('.').at(-1) === "mp4" ?
                        <video src={props.data.header_file} playsInline autoPlay muted loop className="project-img" type="video/mp4"></video>
                        :
                        <img src={props.data.header_file} className="project-img"></img>
                    }
                    {props.data.header_html &&
                        renderSwitch(props.data.header_html)
                    }
                </div>
                :
                <a
                    href={`project/${props.data.slug}`}
                    //Если стоит параметр "светлый фон" добавляем класс
                    className={`project-item colItem-${props.data.column} ${props.data.is_light_background_header ? "light-background" : ""}`}
                    key={props.data.id}
                >
                    <div className="project-item_header">
                        {props?.data?.tags.length >= 1 &&
                            <div className="project-item_tags">
                                <p className="project-item_tag">{props.data.tags[0].name}</p>
                            </div>
                        }

                        <div className="project-item_views">
                            <span>{props.data.views}</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" className={"views-img"} stroke="" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <use href={icons.eye + "#item"}></use>
                            </svg>
                        </div>
                    </div>

                    {props.data.header_file &&
                        props.data.header_file.split('.').at(-1) === "mp4" ?
                        <video src={props.data.header_file} autoPlay muted loop className="project-img" type="video/mp4"></video>
                        :
                        <img src={props.data.header_file} className="project-img"></img>
                    }
                    {props.data.header_html &&
                        renderSwitch(props.data.header_html)
                    }

                    <div className="project-item_content">
                        {/* Если в описании прописано "Собственный проект" и "Own project" мняем стиль */}
                        <p className={props.data.description === t("own_project.title") ? "project-description _own" : "project-description"}>{props.data.description}</p>
                        <p className="project-title">{props.data.title}</p>
                    </div>

                </a>

            }
        </>
    )

}

export default ProjectItem