import React from 'react'
import "./Footer.scss"
import icons from '../../assets/interface_icons/icons'
import img from '../../assets/interface_img/img'
import urls from '../../urls'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'


const Footer = () => {
    const { t, i18n } = useTranslation()
        , { lng } = useParams()
    // , navigate = useNavigate()
    // , location = useLocation()


    return (
        <div className='footer'>
            <div className='footer-top'>
                <div className='licenses'>
                    <p className='year'>2024</p>
                    <div className='licenses-icons'>
                        <img src={icons.cc_logo} alt="CC" />
                        <img src={icons.cc_by} alt="BY" />
                        <img src={icons.cc_nd} alt="ND" />
                    </div>
                    <div className='licenses-text'>
                        <p className='title'>CC BY-ND 4.0 DEED</p>
                        <p className='subtitle'>Attribution-NoDerivs 4.0 International</p>
                    </div>
                </div>
                <a className='footer-terms'  href={`/${lng}/${urls.terms.path}`}>{t("terms.title")}</a>
            </div>
            <hr className='my-12' />
            <div className='footer-bottom'>
                <a href={`/${lng}/${urls.index.path}`} className='d-flex'>
                    <svg width="24" height="24" viewBox="0 0 24 24" className={"logo-mark"} stroke="" fill="" xmlns="http://www.w3.org/2000/svg">
                        <use href={icons.Logo + "#item"}></use>
                    </svg>
                    <h1>{t("indicator.title")}</h1>
                </a>
                <a className='footer-contacts' href={`/${lng}/${urls.contacts.path}`} >{t("contacts.title")}</a>
            </div>
        </div>
    )
}

export default Footer