import React, { useEffect, useState } from 'react'
import PageFrame from '../../components/PageFrame/PageFrame'
import { useParams } from 'react-router-dom'
import './ProjectPage.scss'
import Loader from '../../components/Loader/Loader'
import getFormattedDate from '../../functions/getFormattedDate'
import ContentItem from '../../components/ContentItem/ContentItem'
import MetaTagComponent from '../../components/MetaTagComponent/MetaTagComponent'
import { useTranslation } from 'react-i18next'
import meta from '../../meta'
// import ProjectRequest from '../../api/ProjectRequest'
import ContentSwitch from '../../components/ContentSwitch/ContentSwitch'
import Request from '../../api/Request'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'


const ProjectPage = () => {
    const { id } = useParams()
        , { t } = useTranslation()
        , { lng } = useParams()
        , request = new Request()
        , [error, setError] = useState('')
        , [loading, setLoading] = useState(true)
        , [info, setInfo] = useState({})
        , [content, setContent] = useState([])

    useEffect(() => {
        request.getProjectDetail(id)
            .then(response => {
                if (response.success) {
                    setInfo({
                        title: response.data.title,
                        date: response.data.date,
                    })

                    setContent([...response.data.content])
                    setLoading(false)
                } else {
                    console.log()
                    setError('Ошибка')
                }
            })
    }, [lng])

    const metaTag = {
        "title": info.title,
        "description": "",
        "keywords": ''
    }

    // console.log("info", info);

    return (
        // <PageFrame>
        <>
            <div className='container _project'>
                <Header />
                <MetaTagComponent meta={metaTag} />
                <div className="content">
                    {error && error}
                    {loading ? <Loader /> :
                        error ? error :
                            <div className='Project'>
                                <div className='_project-info'>
                                    <h1 className='Project__title'>{info.title}</h1>
                                    {info.date &&
                                        <span className='Project__date'>{getFormattedDate(info.date)}</span>
                                    }
                                </div>
                                <div className='_project-content'>
                                    {content.map((item, idx) => {
                                        return <div className='Content'><ContentSwitch key={idx} content={item} /></div>
                                    })}
                                </div>
                            </div>
                    }
                </div>
                <Footer />
            </div>
        </>


        // </PageFrame>
    )
}

export default ProjectPage