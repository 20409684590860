import axios from 'axios';
import queryString from 'query-string';

import apiConfig from './apiConfig';
// import urls from '../urls';

const axiosClient = axios.create({
    baseURL: apiConfig.baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
    paramsSerializer: params => queryString.stringify({ ...params, api_key: apiConfig.apiKey })
});

// ждем обновление localStorage
axiosClient.interceptors.request.use(async (config) => {

    // //  Когда есть авторизация
    // // Получаем значение токена
    // const token = JSON.parse(localStorage.getItem('token'));

    // if (token) {
    //     // вставляем значение токена в headers
    //     config.headers['Authorization'] = `Bearer ${token}`;
    // }


    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
});

// axiosClient.interceptors.request.use(async (config) => config);

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    console.log('error', error)
    // console.log('error', error.response.status)
    // if (error.response.status === 401){
    //     window.location.pathname = urls.refresh.path
    // }
    throw error;
});


export default axiosClient;