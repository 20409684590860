import React from "react";
import { Helmet } from "react-helmet-async";
import Opengraph from "../../assets/interface_img/opengraph.jpg";
import { useTranslation } from "react-i18next";

const MetaTagComponent = ({ meta = {} }) => {
  const { t } = useTranslation()
  
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={Opengraph} />
      <meta name="twitter:title" content={meta.title} />
      {/* <meta name="twitter:creator" content="@diepsteph" />
        <meta name="twitter:site" content="@diepsteph" /> */}
      <meta name="twitter:description" content={meta.description} />

      {/* Facebook  */}

      <meta property="og:type" content="website" />
      <meta property="og:url" content="indicatordev.ru" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={Opengraph} />
    </Helmet>
  );
};

export default MetaTagComponent;
