import React from 'react'
import ContentSlider from '../ContentSlider/ContentSlider'
import icons from '../../assets/interface_icons/icons'
import apiConfig from '../../api/apiConfig'
import GallerySlider from '../GallerySlider/GallerySlider'
import GalleryTile from '../GalleryTile/GalleryTile'

const ContentSwitch = ({ content }) => {




    switch (content.type) {
        case 'heading':
            return <div className='container-884'><h2>{content.value}</h2></div>
        case 'paragraph':
            return <div className='container-884'><p className='Content__text' dangerouslySetInnerHTML={{ __html: content.value }} /></div>
        case 'quote':
            return <div className='container-884'><blockquote>{content.value}</blockquote></div>
        case 'image':
            return <div className='container-884'><img className='Content__img' src={`${apiConfig.baseUrl}/${content.value.url}`} /></div>
        case 'link':
            return <div className='container-884'>
                <a href={content.value.link} className='Content__link'>
                    <img src={icons.LinkArrow} alt="" />
                    {content.value.name ? content.value.name : content.value.link}</a>
            </div>
        case 'documents':
            return <div className='container-884'>
                <a href={`${apiConfig.baseUrl}/${content.value[0].url}`} className='Content__document' download={content.value[0].title ? content.value[0].title : 'download'}>
                    <img src={icons.DocumentDownload} alt="" />
                    {content.value[0].title ? content.value[0].title : content.value[0].url}</a>
            </div>
        case 'slider':
            // return <ContentSlider slider={content.value} />
            return <GallerySlider images={content.value} />
        case 'gallery':
            return <GalleryTile images={content.value} />
        default:
            return <div>...</div>
    }


}

export default ContentSwitch