const getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, "")
}

const getFormattedPhoneInputValue = function (numbers, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        // russian number
        if (inputNumbersValue[0] === "9")
            inputNumbersValue = "7 " + inputNumbersValue

        let firstSymbols = (inputNumbersValue[0] === "8") ? "+7 " : "+7 "

        formattedInputValue = firstSymbols //+ " "


        if (inputNumbersValue.length > 1)
            formattedInputValue += + inputNumbersValue.substring(1, 4)

        if (inputNumbersValue.length >= 5)
            formattedInputValue += " " + inputNumbersValue.substring(4, 7)

        if (inputNumbersValue.length >= 8)
            formattedInputValue += "-" + inputNumbersValue.substring(7, 9)

        if (inputNumbersValue.length >= 10)
            formattedInputValue += "-" + inputNumbersValue.substring(9, 11)

        if (formattedInputValue.length < 16)
            setError('Введите телефон полностью')
        else
            setError(false)


        return formattedInputValue
    } else {
        //not russian number
        setError('Не верный формат телефона. Начните ввод с "7", "8" или "9"')
        return "+" + inputNumbersValue.substring(0, 1)
        
    }
}

const getFormattedDateTimeInputValue = function (numbers, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    // if (input.classList.contains('date-mask') || input.classList.contains('date-time-mask')) {
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 2)
    if (inputNumbersValue.length >= 3)
        formattedInputValue += "." + inputNumbersValue.substring(2, 4)
    if (inputNumbersValue.length >= 5)
        formattedInputValue += "." + inputNumbersValue.substring(4, 8)
    // if (dateValidator(formattedInputValue) === false) {
    //     setError("Введите корректную дату")
    // } else {
    //     setError("")
    // }
    // }

    // if (inputNumbersValue.length >= 9)
    //     formattedInputValue += " " + inputNumbersValue.substring(8, 10)
    // if (inputNumbersValue.length >= 11)
    //     formattedInputValue += ":" + inputNumbersValue.substring(10, 12)

    if (formattedInputValue.toString().length < 12)
        setError("Введите корректную дату и время")

    // if (input.classList.contains('time-mask')) {
    //     if (inputNumbersValue.length > 0)
    //         formattedInputValue = " " + inputNumbersValue.substring(0, 2)
    //     if (inputNumbersValue.length >= 3)
    //         formattedInputValue += ":" + inputNumbersValue.substring(2, 4)
    // }
    return formattedInputValue.toString()
}

const getFormattedSnilsValue = function (numbers, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 3)
    if (inputNumbersValue.length >= 4)
        formattedInputValue += "-" + inputNumbersValue.substring(3, 6)
    if (inputNumbersValue.length >= 7)
        formattedInputValue += "-" + inputNumbersValue.substring(6, 9)
    if (inputNumbersValue.length >= 10)
        formattedInputValue += " " + inputNumbersValue.substring(9, 11)
    if (formattedInputValue.toString().length < 14)
        setError('Снилс должен состоять минимум из 11 символов')
    return formattedInputValue.toString()
}

const getFormattedPassportValue = function (numbers, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 2)
    if (inputNumbersValue.length >= 3)
        formattedInputValue += " " + inputNumbersValue.substring(2, 4)
    if (inputNumbersValue.length >= 5)
        formattedInputValue += " " + inputNumbersValue.substring(4, 11)
    if (formattedInputValue.toString().length < 13)
        setError('Введите полностью серию и номер паспорта')
    return formattedInputValue.toString()
}
const getFormattedInnValue = function (numbers, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 12)
    if (formattedInputValue.toString().length < 12) {
        setError('Введите 12 символов')
    }
    return formattedInputValue.toString()
}

function onPhoneInput(input, setError) {
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return ""

    if (input.value.length !== selectionStart) {
        setTimeout(() => {
            input.selectionStart = selectionStart
            input.selectionEnd = selectionStart
        }, 2)
    }
    return getFormattedPhoneInputValue(inputNumbersValue, setError)
}

function onDateTimeInput(input, setError) {
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return ""

    if (input.value.length !== selectionStart) {
        setTimeout(() => {
            input.selectionStart = selectionStart
            input.selectionEnd = selectionStart
        }, 2)
    }
    return getFormattedDateTimeInputValue(inputNumbersValue, setError)
}

function onSnilsInput(input, setError) {
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return ""

    if (input.value.length !== selectionStart) {
        setTimeout(() => {
            input.selectionStart = selectionStart
            input.selectionEnd = selectionStart
        }, 2)
    }

    return getFormattedSnilsValue(inputNumbersValue, setError)
}

function onInnInput(input, setError) {
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return ""

    if (input.value.length !== selectionStart) {
        setTimeout(() => {
            input.selectionStart = selectionStart
            input.selectionEnd = selectionStart
        }, 2)
    }

    return getFormattedInnValue(inputNumbersValue, setError)
}

function onPassportInput(input, setError) {
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return ""

    if (input.value.length !== selectionStart) {
        setTimeout(() => {
            input.selectionStart = selectionStart
            input.selectionEnd = selectionStart
        }, 2)
    }

    return getFormattedPassportValue(inputNumbersValue, setError)
}

export { onPhoneInput, onDateTimeInput, onSnilsInput, onInnInput, onPassportInput }