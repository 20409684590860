import React from 'react'

const FieldError = (props) => {
    return (
        <>
            {props.field.help_text && !props.error && <span className="help-text ">{props.field.help_text}</span>}
            {/* {props.error && props.fieldDirty && <span className="error-message">{props.error}. {props.field.help_text}</span>} */}
            {props.error && <span className="error-message">{props.error} {props.field.help_text}</span>}
        </>
    )
}

export default FieldError