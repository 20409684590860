import BaseModelAPI from "./BaseModelAPI";
import axiosClient from "./axiosClient";
import { API_MODEL } from "./const";

class Request extends BaseModelAPI {
    constructor() {
        super( `/${localStorage.getItem('i18nextLng')}${API_MODEL.entity}`);
    }
   
    async getContacts() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.contacts.url });
    }
    async getRequisites() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.requisites.url });
    }
    async getRequisitesOptions() {
        return this.makeRequest(axiosClient.options, {method: API_MODEL.urls.requisites.url });
    }
    async getPartners() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.partners.url });
    }
    async getRunningLine() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.run_line.url });
    }
    async getProjects() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.projects.url, urlParams: `?locale=${localStorage.getItem('i18nextLng')}&fields=*` });
    }
    async getProjectDetail(id) {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.projects.url, id: id, urlParams: `?locale=${localStorage.getItem('i18nextLng')}&fields=*` });
    }
    async discussOptions() {
        return this.makeRequest(axiosClient.options, {method: API_MODEL.urls.discuss.url });
    }
    async discussPost(data) {
        return this.makeRequest(axiosClient.post, {method: API_MODEL.urls.discuss.url, body: data });
    }
    async getTermsOfUse() {
        return this.makeRequest(axiosClient.get, {method: API_MODEL.urls.terms.url });
    }
}

export default Request;
