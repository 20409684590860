import React, { useState } from "react"
import FieldError from "./FieldError"

function SwitchItem(props) {

    const handler = (e) => {
        props.setValue(props.field.name, e.target.checked)
    }

    return (
        <div className={"form__item switch " + props.classes}>
            <FieldError {...props} />

            <div>
                <input type="checkbox" id={props.field.name} name={props.field.name} className="switch__input" onChange={handler} checked={props.value ?? false} />

                <label htmlFor={props.field.name} className={`switch__label ${props.value ? 'active' : ''}`}>
                    <span className={`switch__toggler ${props.value ? 'active' : ''}`}></span>
                    {props.field.label}
                </label>
            </div>


        </div>
    )
}                                                             

export default SwitchItem