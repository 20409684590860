import Logo from './indicator-logo.svg'
import hole from './card-hole.svg'
import right from './arrow-right.svg'
import grid from './grid.svg'
import Gerb from './Gerb_main_img.svg'
import frame from './frame_main_img.svg'
import smallGerb from './small-gerb.svg'



export default {
    Logo,
    hole,
    right,
    grid,
    Gerb,
    frame,
    smallGerb
}