import React from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import IndexPage from "./pages/IndexPage/IndexPage";
import urls from "../src/urls"

import "./styles/_base.scss"
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/:lng/">
        {/* {Object.values(urls).map((item, idx) => <Route key={idx} {...item} />)} */}
        <Route index element={<IndexPage />} />
        <Route path={urls.contacts.path} element={<ContactsPage {...urls.contacts} />} />
        <Route path={urls.project.path} element={<ProjectPage/>} />
        <Route path={urls.terms.path} element={<TermsOfUse/>} />
      </Route>
      <Route path={urls.contacts.path} element={<Navigate to={`/${localStorage.getItem('i18nextLng') || 'ru'}/${urls.contacts.path}`} />} />
      <Route path={urls.index.path} element={<Navigate to={`/${localStorage.getItem('i18nextLng') || 'ru'}/${urls.index.path}`} />} />
      <Route path={urls.project.path} element={<Navigate to={`/${localStorage.getItem('i18nextLng') || 'ru'}/${urls.project.path}`} />} />
      <Route path={urls.terms.path} element={<Navigate to={`/${localStorage.getItem('i18nextLng') || 'ru'}/${urls.terms.path}`} />} />

    </>
  )
);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
