import React from 'react'
import './Loader.scss'
import { useTranslation } from 'react-i18next'

const Loader = () => {

    const { t } = useTranslation()
    return (
        <div className='Loader'>
            <span className='Loader__text'>{t("ui.loading")}</span>
        </div>
    )
}

export default Loader