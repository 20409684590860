import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "./AnimateGerb.scss"
import img from '../../assets/interface_img/img'
import { useWindowSize } from '../../functions/useWindowSize';
import { useEffect } from 'react';


const AnimateGerbComponent = () => {
    const { t, i18n } = useTranslation();
    const [mouse_x, setMouse_x] = useState(0)
    const [mouse_y, setMouse_y] = useState(0)
    const sceneRef = useRef()
    const [width, height] = useWindowSize()

    const superAnimateGerb = (evt) => {
        const scene = sceneRef.current
        let sceneParams = {
            x: scene.getBoundingClientRect().x,
            y: scene.getBoundingClientRect().y,
            w: scene.getBoundingClientRect().width,
            h: scene.getBoundingClientRect().height,
        }
        let x = evt.clientX;
        let y = evt.clientY;
        let transformX = (x - (sceneParams.x + (sceneParams.w / 2))) / window.innerWidth * 100;
        let transformY = ((y - (sceneParams.y + (sceneParams.h / 2))) / 10) * -1;

        setMouse_x(transformX);
        setMouse_y(transformY);

    }

    const endAnimate = () => {
        const x_step = mouse_x / 20
        const y_step = mouse_y / 20
        let i = 0

        let timerId = setInterval(() => {
            i++
            setMouse_x(prev => {
                let val = prev - x_step
                return val
            });
            setMouse_y(prev => {
                let val = prev - y_step
                return val
            });
            if (i > 20) {
                clearInterval(timerId)
            }
        }, 10)
    }

    useEffect(() => {
        if (width < 1024) {
            setMouse_x(0)
            setMouse_y(0)
        }
    }, [width])

    return (
        <section className='digital-gerb-animate' onMouseMove={width > 1023 ? superAnimateGerb : () => { }} onMouseLeave={width > 1023 ? endAnimate : () => { }}>
            <div ref={sceneRef} className="scene scene_move">
                <img src={img.grid} className="main_screen-grid" style={{ transform: `translate(-50%, -50%) rotateY(${mouse_x}deg) rotateX(${mouse_y}deg) translateZ(-40px) scale(1.04)` }} />
                <img src={img.Gerb} className="main_screen-grid" style={{ transform: `translate(-50%, -50%) rotateY(${mouse_x}deg) rotateX(${mouse_y}deg)` }} />
                <img src={img.frame} className="main_screen-grid" style={{ transform: `translate(-50%, -50%) rotateY(${mouse_x}deg) rotateX(${mouse_y}deg) translateZ(40px) scale(0.96)` }} />
            </div>
        </section>
    )
}

export default AnimateGerbComponent