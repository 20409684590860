import React, {useState} from "react"

function CheckboxItem(props) {
    const [value, setValue] = useState(props.value ? typeof props.value === 'string' ? JSON.parse(props.value) : props.value : [])
    // console.log('props.value', typeof props.value)
    // console.log('props.value', props.value)
    const checkboxHandler = (e, val) => {
        if (e.target.checked) {
            setValue([...value, val])
        } else {
            setValue(value.filter(v => v !== val))
        }
    }
    return (
        <div className={"form__item " + props.classes}>
            {props.field.help_text && !props.error && <span className="help-text ">{props.field.help_text}</span>}
            {props.error && <span className="error-message">{props.error}. {props.field.help_text}</span>}
            <input type="text" name={props.field.name} onChange={() => {}} value={JSON.stringify(value)}/>
            <div>
                {props.field.choices.map((choice, idx) => {
                    return (
                        <div className="checkbox-wrap form__checkbox" key={idx}>
                            <input id={`${props.field.name}-${idx}`} type="checkbox" onChange={e => checkboxHandler(e, choice.value)}  checked={value.some(val => val === choice.value)} />
                            <label htmlFor={`${props.field.name}-${idx}`}>{choice.display_name}</label>
                        </div>
                        // <div className="form__radio" key={idx}>
                        //     <input id={`${props.field.name}-${idx}`} type="radio" name={props.field.name} onChange={e => setValue(e.target.value)} value={choice.value} checked={value.toString() === choice.value.toString() ? 'checked' : false}/>
                        //     <label htmlFor={`${props.field.name}-${idx}`}>{choice.display_name}</label>
                        // </div>
                    )
                })}
            </div>


            <label htmlFor={props.field.name}>{props.field.label}</label>
        </div>
    )
}

export default CheckboxItem