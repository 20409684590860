import { configureStore } from '@reduxjs/toolkit'

//import userDataReducer from './userDataSlice';
// import subregionsReducer from './subregionsSlice'
// import bodyScrollReducer  from './BodyScrollSlice'


export const store = configureStore({
  reducer: {
    // subregions: subregionsReducer,
    // body_scroll: bodyScrollReducer
    //user_data: userDataReducer,
  },
})