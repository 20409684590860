import React, { useState, useEffect } from "react"

import RadioItem from "./RadioItem";
import CheckboxItem from "./CheckboxItem";
import Boolean from "./Boolean";
import FileItem from "./FileItem";
import TextareaItem from "./TextareaItem";
import './fields.scss'
import TextItem from "./TextItem";
import SwitchItem from "./SwitchItem";
import { emailValidator } from "../../../functions/validators";

const FormItem = (props) => {
    const [fieldDirty, setFieldDirty] = useState(props.field.error ? true : false)
        , required = props.required ? props.required : props.field.required || false
        , read_only = props.read_only ? props.read_only : props.field.read_only || false

    const setError = (str) => {
        props.setError(props.field.name, str)
    }

    const setData = (val) => {
        props.setValue(props.field.name, val)
    }

    const validate = (val) => {
        if (required === true) {
            if (val.length === 0) {
                setError('Заполните поле.')
            } else {
                if (props.field.validator) {
                    setError(props.field.validator(val))
                } else {
                    if (props.field.type === 'email') {
                        setError(emailValidator(val))
                    }
                }
            }
        } else {
            if (val.length > 0) {
                if (props.field.validator) {
                    setError(props.field.validator(val))
                } else {
                    if (props.field.type === 'email') {
                        setError(emailValidator(val))
                    }
                }
            }
        }
    }

    const blurHandle = e => {
        let val = e.target.value
        // console.log('val', val?.length)
        // console.log('required', required)
        if (props.field.mask)
            val = props.field.mask(e.target, setError)
        if (required || val?.length > 0)
            setFieldDirty(true)
        if (!required && val?.length === 0)
            setFieldDirty(false)
        if (required === true && val?.length === 0)
            setError('Заполните поле.')
    }

    const fieldHandler = e => {
        let input = e.target
            , val = input.value

        setError('')
        if (props.field.mask)
            val = props.field.mask(e.target, setError)

        setData(val)
        validate(val)
    }



    switch (props.field.type) {
        case "hidden":
            return <input type={props.field.type} name={props.field.name} value={props.value} onChange={() => { }} />
        case "boolean":
            return <Boolean {...props} required={required} readOnly={read_only} />
        case "checkbox":
            return <CheckboxItem {...props} required={required} readOnly={read_only} />
        case "switch":
            return <SwitchItem {...props} required={required} readOnly={read_only} />
        case "radio":
            return <RadioItem {...props} required={required} readOnly={read_only} />
        case "file":
            return <FileItem {...props} required={required} readOnly={read_only} setValue={setData} />
        case "textarea":
            return <TextareaItem {...props} fieldHandler={fieldHandler} required={required} blurHandle={blurHandle} readOnly={read_only} />
        default:
            return <TextItem {...props} fieldDirty={fieldDirty} handler={fieldHandler} required={required} blurHandle={blurHandle} readOnly={read_only} />
    }

}

export default FormItem