import React, { useEffect, useState } from "react";
import MetaTagComponent from "../../components/MetaTagComponent/MetaTagComponent";
import meta from "../../meta";
import Header from "../../components/Header/Header";
import PageFrame from "../../components/PageFrame/PageFrame";
import { useTranslation } from "react-i18next";
import "./IndexPage.scss"
import ProjectItem from "../../components/ProjectItem/ProjectItem";
// import ProjectRequest from "../../api/ProjectRequest";
import Request from "../../api/Request";




const IndexPage = () => {

  const { t, i18n } = useTranslation()
    , [projects, setProjects] = useState()
    , request = new Request()

  useEffect(() => {
    request.getProjects()
      .then(response => {
        if (response.success) {
          // console.log('response', response.data.items)
          setProjects(response.data.items)
        } else {
          console.log("Ошибка")
        }
      })


  }, [i18n.language])

  const metaTag = {
    "title": t(meta.index.title),
    "description": t(meta.index.description),
    "keywords": t(meta.index.keywords)
  }

  return (
    <>
      <MetaTagComponent meta={metaTag} />
      <PageFrame>
        <div className="content">
          <div className="projects-grid">
            {projects &&
              projects.map((item, idx) => {
                return <ProjectItem data={item} key={idx} />;
              })}
          </div>
        </div>
      </PageFrame>
    </>
  );
};

export default IndexPage;
