import React from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import "./PageFrame.scss";
import { useTranslation } from 'react-i18next';


const PageFrame = ({ children }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className='container'>
                <Header />
                {children}
                <Footer />
            </div>
        </>
    )
}

export default PageFrame
