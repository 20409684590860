import cc_by from './cc-by.svg'
import cc_logo from './cc-logo.svg'
import cc_nd from './cc-nd.svg'
import Logo from './indicator-mark.svg'
import SwiperGalleryArrow from './SwiperGalleryArrow.svg'
import DocumentDownload from './DocumentDownload.svg'
import LinkArrow from './LinkArrow.svg'
import XClose from './x-close.svg'
import Attachment from './attachment.svg'
import repeat from './repeat.svg'
import eye from './eye.svg'

export default {
    cc_by,
    cc_logo,
    cc_nd,
    Logo,
    SwiperGalleryArrow,
    DocumentDownload,
    LinkArrow,
    XClose,
    Attachment,
    repeat, 
    eye
}