import React, { useState } from "react"
import icons from "../../..../../../assets/interface_icons/icons"
import { useTranslation } from "react-i18next"

function FileItem(props) {
    const [loading, setLoading] = useState(false)
        , { t, i18n } = useTranslation()

    const removeField = (e, index) => {
        e.preventDefault()
        // const arr = [...props.value]
        // arr.splice(index, 1)
        props.setValue(false)
        props.setError(props.field.name, false)
    }

    const uploadFile = function (e) {
        const input = e.target
            , files = [...input.files]
        // console.log('files', files)
        setLoading(true)
        props.setError(props.field.name, false)
        props.setValue(files[0])
        setLoading(false)
    }

    return (
        <div className={"form__item file__item " + props.classes}>
            <input id={"add_" + props.field.name} onChange={e => uploadFile(e)} accept={props.field.accept || false} className="d-none" type="file" />
            <div className="file__item_footer">
                {props.value ? 
                   // removed map for multiple files
                    
                        <div className="file__item_wrap">
                            <span className="file-label" title={props.value.name} >{props.value.name}</span>
                            <span onClick={e => removeField(e)} className="delete-file"></span>
                        </div>
                    
                 : ''}
                {!props.value &&
                    <label htmlFor={"add_" + props.field.name} className="add-file__btn mt-8">
                        <img src={icons.Attachment} alt="" />
                        {loading ? t("ui.loading") : t("ui.add_file")}</label>}
                {props.error && <span className="error-message">{props.error}</span>}
            </div>


        </div>
    )
}

export default FileItem