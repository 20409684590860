import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import "./Header.scss"
import urls from '../../../src/urls'
import icons from '../../assets/interface_icons/icons'

const Header = () => {
  const [menuActive, setMenuActive] = useState()
  // const dispatch = useDispatch()
  // const scroll = () => dispatch(bodyScroll())
  // const noScroll = () => dispatch(bodyNoScroll())

  const { t, i18n } = useTranslation()
    , { lng } = useParams()
    , navigate = useNavigate()
    , location = useLocation()

  const [isEnglish, setIsEnglish] = useState(lng === "en" ? true : false)

  const changeLanguage = (language) => {

    navigate(location.pathname.replace(`/${lng}/`, `/${language}/`))
    i18n.changeLanguage(language);
    // window.location.pathname = location.pathname.replace(`/${lng}/`, `/${language}/`)
    setIsEnglish(!isEnglish)
  };

  // useEffect(() => {
  //   if (menuActive) {
  //     noScroll()
  //   } else {
  //     scroll()
  //   }
  // }, [menuActive])

  const navigation = [
    urls.index,
    urls.contacts,
  ]

  return (
    <section className={`header`}>

      <div className='header__container'>
        <div className='language'>

          <button onClick={() => changeLanguage(isEnglish ? "ru" : "en")} className='language__button'>
            <svg width="16" height="16" viewBox="0 0 16 16" className={"lang-icon"} stroke="" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use href={icons.repeat + "#item"}></use>
            </svg>
            <span>
              {isEnglish ? "Рус" : "Eng"}
            </span>
          </button>
        </div>
        <a href={`/${lng}/${urls.index.path}`} className='d-flex'>
          <svg width="24" height="24" viewBox="0 0 24 24" className={"logo-mark"} stroke="" fill="" xmlns="http://www.w3.org/2000/svg">
            <use href={icons.Logo + "#item"}></use>
          </svg>
          <h1>{t("indicator.title")}</h1>
        </a>
        <div className={`header-menu`}>
          <nav className={`nav`}>
            <a href={`/${lng}/${urls.contacts.path}`} className="nav__link">{t("contacts.title")}</a>
          </nav>
        </div>
        {/* {menuActive && <div className='header-menu__backdrop' onClick={() => setMenuActive(false)}></div>} */}

      </div>
    </section>
  )
}

export default Header