import React, { useEffect, useState } from 'react'
import Request from "../../api/Request";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MetaTagComponent from '../../components/MetaTagComponent/MetaTagComponent';
import PageFrame from '../../components/PageFrame/PageFrame';
import "./TermsOfUse.scss"

const TermsOfUse = () => {
    const { t, i18n } = useTranslation()
        , request = new Request()
        , { lng } = useParams()
        , [error, setError] = useState('')
        , [terms, setTerms] = useState()


    useEffect(() => {
        request.getTermsOfUse()
            .then(response => {
                if (response.success) {

                    setTerms(response.data.results[0])
                } else {
                    console.log()
                    setError('Ошибка')
                }
            })

    }, [lng])


    console.log(terms);

    return ( 
        <PageFrame>
            <div className="content">
                {error && error}
                <div className='container-884'>
                    <h1 className='terms_title'>{t("terms.title")}</h1>

                    <p className='terms_text' dangerouslySetInnerHTML={{ __html: terms?.text }} />


                </div>
            </div>
        </PageFrame>
    )
}

export default TermsOfUse