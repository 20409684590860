
function fieldToArray(fields) {
    if (!fields) {
        return [];
    }

    const fieldEntries = Object.entries(fields);

    if (fieldEntries.length === 0) {
        return [];
    }

    const resultArray = fieldEntries.map(([key, value]) => ({ key, value }));
    return resultArray;
}

export { fieldToArray }