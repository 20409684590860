export const API_MODEL = {
    entity: '/api/',
    urls: {
        contacts: {
            url: 'about-us/contacts/get-active/'
        },
        requisites: {
            url: 'about-us/requisites/'
        },
        partners: {
            url: 'about-us/customer-links/'
        },
        run_line: {
            url: 'about-us/running-lines/get-active/'
        },
        projects: {
            url: 'v2/projects/'
        },
        discuss: {
            url: 'projects/discuss/'
        },
        terms: {
            url: 'about-us/privacy-policy/'
        }
    },
}

